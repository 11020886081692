export default {
  init() {
    // JavaScript to be fired on all pages

    // Contact form inputs
    var contactFormInputs = document.querySelectorAll('.wpcf7-form-control'); // or your custom input class
    contactFormInputs.forEach(function(element) {
      element.addEventListener('focus', function(event) {
        event.target.parentElement.classList.add('focused');
      });
      element.addEventListener('blur', function(event) {
        event.target.parentElement.classList.remove('focused');
      });
      element.addEventListener('change', function(event) {
      event.target.value
          ? event.target.parentElement.classList.add('valid')
          : event.target.parentElement.classList.remove('valid')
      });
    });

    // Navbar resize/show/hide
    $(function () {
      let prevScrollpos = $(window).scrollTop();
      let hideNavBarOffsetHeight = $(window).height() * 0.3;
      let styleNavBarOffsetHeight = $(window).height() * 0.15;

      $(window).scroll(function () {

        // When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar
        let currentScrollPos = window.pageYOffset;

        if ($(window).scrollTop() > hideNavBarOffsetHeight) {
          if (prevScrollpos > currentScrollPos) {
            $('#nav-site').removeClass('nav-hide')
            $('.wrap').addClass('wrap-scrolled');
            $('.nav-light').removeClass('navbar-dark');
          } else {
            $('#nav-site').addClass('nav-hide');
            $('.nav-light').removeClass('navbar-dark');
          }
        }

        prevScrollpos = currentScrollPos;

        // When the user scrolls down from the top of the document, alter navbar's styling
        if ($(window).scrollTop() > styleNavBarOffsetHeight) {
          $('#nav-site').addClass('shadow').addClass('nav-scrolled');
          $('body').removeClass('modal-open');
        } else {
          $('#nav-site').removeClass('shadow').removeClass('nav-scrolled')
          $('.wrap').removeClass('wrap-scrolled');
          $('body').removeClass('modal-open');
        }
      });
    });

    var wpcf7Elm = document.querySelector( '.main .wpcf7' );
    wpcf7Elm.addEventListener('wpcf7mailsent', function () {
      $('.banner').addClass('min-h-auto').addClass('h-0').addClass('overflow-hidden').addClass('mt-13');
      $('.h1').addClass('h-0').addClass('mb-auto').addClass('overflow-hidden');
      window.scrollTo(0, 0);
    }, false );
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
